import React, { useState } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import { baseViewDispatch, createAlert } from 'lib/util'
import {
  Container,
  Segment,
  Header,
  Form,
  Input,
  Button,
  List,
} from 'semantic-ui-react'

const FaissManager = ({ login, success, error }) => {
  const [text, setText] = useState('')
  const [entryId, setEntryId] = useState('')
  const [query, setQuery] = useState('')
  const [k, setK] = useState(3)
  const [searchResults, setSearchResults] = useState([])

  const token = login.data.user.token

  const addTextEntry = async () => {
    try {
      await axios.post(
        'http://localhost:5001/faiss/add',
        { text },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      success(
        createAlert(
          'Entry added.',
          'The entry was successfully added!',
          'View',
          () => {
            // Navigation or additional actions can go here.
          }
        )
      )
    } catch (err) {
      error(
        createAlert(
          'Error adding entry.',
          'There was an error adding the entry.',
          'Retry',
          () => {
            // Retry logic or additional actions can go here.
          }
        )
      )
    }
  }

  const deleteEntryById = async () => {
    try {
      await axios.delete(`http://localhost:5001/faiss/delete/${entryId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      success(
        createAlert(
          'Entry deleted.',
          'The entry was successfully deleted!',
          'View',
          () => {
            // Navigation or additional actions can go here.
          }
        )
      )
    } catch (err) {
      error(
        createAlert(
          'Error deleting entry.',
          'There was an error deleting the entry.',
          'Retry',
          () => {
            // Retry logic or additional actions can go here.
          }
        )
      )
    }
  }

  const searchEntries = async () => {
    try {
      const response = await axios.get(
        `http://localhost:5001/faiss/search?q=${query}&k=${k}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      setSearchResults(response.data.results)
      success(
        createAlert(
          'Search Completed',
          'Your search results have been updated!',
          'View',
          () => {}
        )
      )
    } catch (err) {
      error(
        createAlert(
          'Error Searching',
          'There was an error performing the search.',
          'Retry',
          () => {}
        )
      )
    }
  }

  return (
    <Container style={{ padding: '2em 0' }}>
      <Header as="h2" textAlign="center">
        Faiss Manager
      </Header>

      {/* Add New Entry Section */}
      <Segment padded>
        <Header as="h3">Add New Entry</Header>
        <Form>
          <Form.Field>
            <Input
              fluid
              size="large"
              placeholder="Enter text to add"
              value={text}
              onChange={(e) => setText(e.target.value)}
            />
          </Form.Field>
          <Button primary fluid size="large" onClick={addTextEntry}>
            Add Text Entry
          </Button>
        </Form>
      </Segment>

      {/* Delete Entry Section */}
      <Segment padded>
        <Header as="h3">Delete Entry</Header>
        <Form>
          <Form.Field>
            <Input
              fluid
              size="large"
              placeholder="Enter entry ID to delete"
              value={entryId}
              onChange={(e) => setEntryId(e.target.value)}
            />
          </Form.Field>
          <Button negative fluid size="large" onClick={deleteEntryById}>
            Delete Entry
          </Button>
        </Form>
      </Segment>

      {/* Search for Similar Entries Section */}
      <Segment padded>
        <Header as="h3">Search for Similar Entries</Header>
        <Form>
          <Form.Field>
            <Input
              fluid
              size="large"
              placeholder="Enter search query"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />
          </Form.Field>
          <Form.Field>
            <Input
              fluid
              size="large"
              type="number"
              placeholder="Number of results (k)"
              value={k}
              onChange={(e) => setK(e.target.value)}
            />
          </Form.Field>
          <Button secondary fluid size="large" onClick={searchEntries}>
            Search Entries
          </Button>
        </Form>
      </Segment>

      {/* Display Search Results */}
      {searchResults && searchResults.length > 0 && (
        <Segment padded>
          <Header as="h4">Search Results</Header>
          <List divided relaxed>
            {searchResults.map((result, index) => (
              <List.Item key={index}>
                <List.Content>
                  <List.Header>Result {index + 1}</List.Header>
                  <List.Description>
                    <pre>{JSON.stringify(result, null, 2)}</pre>
                  </List.Description>
                </List.Content>
              </List.Item>
            ))}
          </List>
        </Segment>
      )}
    </Container>
  )
}

const mapStateToProps = (state) => ({
  login: state.login,
})

const mapDispatchToProps = (dispatch) => ({
  ...baseViewDispatch(dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(FaissManager)
